@font-face {
  font-family: 'Helvetica Neue';
  //font-family: 'Helvetica Neue LT W05 65 Medium';
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/fonts/helvetica-neue/5664103/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2') format('woff2'),
    url('../assets/fonts/helvetica-neue/5664103/7802e576-2ffa-4f22-a409-534355fbea79.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue';
  //font-family: 'Helvetica Neue LT W05_75 Bold';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/fonts/helvetica-neue/5664150/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2') format('woff2'),
  url('../assets/fonts/helvetica-neue/5664150/7b415a05-784a-4a4c-8c94-67e9288312f5.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue';
  //font-family: 'Helvetica Neue LT W05 55 Roman';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url('../assets/fonts/helvetica-neue/5664093/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2') format('woff2'),
  url('../assets/fonts/helvetica-neue/5664093/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff') format('woff');
}