// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$avis2-primary: mat.define-palette(mat.$indigo-palette);
$avis2-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$avis2-theme: mat.define-light-theme(
  (
    color: (
      primary: $avis2-primary,
      accent: $avis2-accent,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($avis2-theme);

@import 'styles/material-icons';
@import 'styles/helvetica-neue';
@import 'styles/_variables.scss';

// Typography
$custom-typography: mat.define-typography-config(
  $font-family: "'Helvetica Neue', sans-serif",
);

@include mat.core($custom-typography);

$avis-brand: (
  50: #e0f7f9,
  100: #b2ebf1,
  200: #80dee8,
  300: #4dd0df,
  400: #26c6d8,
  500: #00bcd2,
  600: #00acbf,
  700: #0097a5,
  800: #01838d,
  900: #026063,
  A100: #67a2ff,
  A200: #3482ff,
  A400: #0163ff,
  A700: #0059e6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$avis-fe-primary: mat.define-palette($avis-brand);
$avis-fe-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$avis-fe-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$avis-fe-theme: mat.define-light-theme(
  (
    color: (
      primary: $avis-fe-primary,
      accent: $avis-fe-accent,
      warn: $avis-fe-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-colors($avis-fe-theme);
@font-face {
  font-family: Yupic;
  src: url(./assets/fonts/Yupic-2016-rex2.ttf) format('opentype');
}
/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100% !important;
}
body {
  margin: 0;
  background-color: #F3F4F8 !important;
}

.flex {
  display: flex;
}

.custom-dialog-container .mat-dialog-container {
  overflow-y: hidden;
}

.add-station-div {
  .add {
    margin-right: 5px;
  }
}

.blue-snackbar, .red-snackbar, .red-snackbar-validate {
  background-color: #FFFFFF;
  font-size: 14px;
  color: #26272D;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 8px;
  margin: 10px 8px 10px 23px;
  max-width: 435px !important;
}

.red-snackbar-validate {
  white-space: pre-wrap;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  display: flex;
}

.mat-tooltip.planModalTooltip {
  font-size: 14px !important;
}

.weeklyMatSelectPanel {
  min-width: 480px !important;
}

.routeMatSelectPanel {
  min-width: 400px !important;
}

.table-top-area {
  display: flex;
  justify-content: space-between;
  margin-right: 12px;
}

//Material Design overwrite
.table-style {
  width: 100%;
  margin-top: 0;
  //border-spacing: 0 !important;
  border-collapse: separate !important;
  border-radius: 4px 4px 0 0;
  border: 1px solid #EDEDED;
  border-bottom: none;
  overflow: hidden;

  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type {
    padding-left: 16px;
  }

  th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
    padding-right: 16px;
  }

  .mat-header-cell {
    font-weight: bold;
  }

  .mat-cell {
    span {
      &.light {
        opacity: 0.3;
      }
    }
  }

  .mat-header-row {
    th.mat-header-cell {
      &:first-child {
        border-left: none;
      }
    }
  }

  .mat-row:hover .mat-cell {
    background-color: #EEEEF4;
    .cell-actions {
      display: flex;
    }
  }

  .mat-row:focus-within {
    background-color: #EEEEF4;
  }

  .mat-row:nth-child(odd){
    background-color: #F9F9FB;
  }

  .mat-header-cell,
  .mat-cell {
    font-size: 14px !important;
    color: #26272D !important;
    padding: 0 8px;
    border-bottom-color: #EDEDED;
  }

  .cell-actions {
    display: none;
    button {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      &:hover {
        background-color: #FFFFFF;
        box-shadow: 0 1px 3px #0000004D;
        border-radius: 4px;
        opacity: 0.8;
      }
    }
  }

  .cell-size-actions {
    padding: 0 !important;
  }
}

.mat-button-toggle {
  background-color: transparent !important;
  border: none !important;
}

.mat-paginator {
  border: 1px solid #EDEDED;
  border-top: none;
  border-radius: 0 0 4px 4px;
}

@mixin svg-icons {
  width: 28px !important;
  height: 28px !important;
}

.add {
  @include svg-icons;
  color: #0096a5;
}

.yes, .transit {
  @include svg-icons;
  color: #579bfc !important;
}

.short {
  @include svg-icons;
  color: #a25ddc !important;
}

.yes-action {
  @include svg-icons;
  color: #5cb85c !important;
}

.warning {
  @include svg-icons;
  color: #e9923d !important;
}

.show {
  @include svg-icons;
  color: #515257;
  &.active {
    color: #0096A5 !important;
  }
}

.refresh {
  @include svg-icons;
  color: #515257;
}

.details, .copy, .cut, .edit, .activate,
.delete, .station-track, .station-subsequent,
.station-premises, .arrow-sort, .user-role,
.user-station, .clear {
  @include svg-icons;
  color: #7f8085;
}

mat-error {
  margin-top: -20px;
}

.icons {
  width: 24px;
  cursor: pointer;
}

.mat-menu-panel.user-menu-dropdown {
  margin-top: 10px;
}

.mat-h1, .mat-headline, .mat-typography .mat-h1, .mat-typography .mat-headline, .mat-typography h1 {
  font-weight: 500 !important;
  font-size: 18px !important;
  margin: 0 0 5px;
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}